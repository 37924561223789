.resume-onepage {
  width: 90%;
}

/* .resume-pdf {
  padding: .5rem;
  margin-top: 2rem;
  background-color: #8d1c38;
  color: white;
  border: none;
} */


.resume-pdf {
  top: 0;
  left: 0;
  margin-top: 2rem;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  background-color: rgba(141, 28, 56, 0.23);
  border: 3px solid #8d1c38;
  border-radius: 2rem;
  
  text-transform: uppercase;
  color: white;
  font-family: arial;
  letter-spacing: 1px;
  
  box-shadow: -6px 6px 0 brown;
  
  &:hover {
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 brown;
    
    &::after {
      top: 1px;
      left: -2px;
      width: 4px;
      height: 4px;
    }
    
    &::before {
      bottom: -2px;
      right: 1px;
      width: 4px;
      height: 4px;
    }
  }
  
  &::after {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    top: 2px;
    left: -4px;
    width: 8px;
    height: 8px;
    background-color: brown;
    transform: rotate(45deg);
    z-index: -1;
    
  }
  
  &::before {
    transition: all .15s linear 0s;
    content: '';
    position: absolute;
    bottom: -4px;
    right: 2px;
    width: 8px;
    height: 8px;
    background-color: brown;
    transform: rotate(45deg);
    z-index: -1;
  }
}

a.btn { position: relative; }

a:active.btn {
  top: 6px;
  left: -6px;
  box-shadow: none;
  
  &:before {
    bottom: 1px;
    right: 1px;
  }
  
  &:after {
    top: 1px;
    left: 1px;
  }
}

.testresume {
  font-family: arial;
}
.resume-header {
  font-size: 7dvh;
}
.resume-section-header {
  font-size: 3dvh;
}

.resume-text {
  color: blue;
}