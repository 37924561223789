.contact-form {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: auto;
  font-family: "azo-sans-uber", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .contact-form {
    width: 70%;
  }
  .submit-button {
    font-size: .75rem;
  }
}

@media screen and (min-width: 768px) {
  .contact-form {
  font-size: 1rem;
  }
}

.contact-form label {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.contact-form textarea {
  height: 7rem;
}


.submit-button {
  background-color: rgba(103, 70, 117, 0.396);
  font-family: "azo-sans-uber", sans-serif;
  font-weight: 400;
  width: 30%;
  padding: 0.5rem;
  border-radius: 2rem;
  border: 3px solid #674675;
  color: white;
  margin: 1rem;
  align-self: center;
  text-align: center;
}

.contact-logos {
  height: 4rem;
  padding: 0 1rem;
  transition: transform 0.7s ease-in-out;
}


.contact-links-page {
  display: flex;
  justify-content: center;
}

.contact-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}

.contact-links :hover {
  transform: scale(1.1);
}

.contact-form,
.thankyou,
.intouch {
  color: #674675;
}

.thankyou {
  font-family: "azo-sans-uber", sans-serif;
  font-weight: 400;
  font-size: 2rem;
}

.thankyou-form {
  padding-top: 2rem;
}

.intouch {
  font-family: Arial, Helvetica, sans-serif;
}

.contact-copy {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  padding: 2rem;
  border: 3px solid #674675;
  background-color: rgba(103, 70, 117, 0.396);
  border-radius: 2rem;
  width: 50%;
  margin: 2rem auto;
}

.copy-para {
  margin-top: 2dvh;
  line-height: 1.5rem;
}

.contact-form input {
  line-height: 2rem;
}
