.navigation {
  height: 10dvw;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background: linear-gradient(
    135deg,
    rgba(191, 136, 176, 1) 0%,
    rgba(176, 186, 217, 1) 100%
  );
  color: black;
}

.hero {
  --color-1: #28007e;
  --color-2: #77618c;
  height: 10dvw;
  width: 100%;
  background-color: #8d1c38;
  background-image: radial-gradient(
    circle farthest-side at var(--x, 100px) var(--y, 100px),
    var(--color-1) 0%,
    transparent 100%
  );
  /* transition: var(--color-1) 100ms linear, var(--color-2) 100ms linear; */
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
}

.logo {
  color: #f2c2c6;
  font-family: "azo-sans-uber", sans-serif;
  font-weight: 400;
  font-size: 7dvw;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  font-family: "azo-sans-uber", sans-serif;
  font-weight: 400;
  display: block;
  width: 100%;
  font-size: 2dvw;
}


.hamburger {
  border: 0;
  height: 8dvw;
  width: 8dvw;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 8%;
  right: 25px;
  /* transform: translateY(-50%); */
  display: none;
}
.hamburger:hover {
  transform: rotate(360deg);
  transition: transform 0.5s ease-in-out;
}

.nav-items {
  margin-right: 3rem;
}

.nav-items :hover {
  color: white;
  }

.location {
  background-color: #8d1c38;
  border-radius: 2rem;
  padding: 1dvw;
  color: white;
}

.notlocation {
  color: #f2c2c6;
  border-radius: 2rem;
  padding: 1dvw;
}

@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .navigation-menu ul {
    display: none;
  }
  .nav-items :hover {
    color: #674675;
  }
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    background-color: #bb90a6;
    border: 3px solid #674675;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: #f2c2c6;
    width: 100%;
    padding: 1.5rem 0;
    font-size: 2rem;
  }
  .navigation-menu li:hover {
    background-color: #eee;
    border-radius: 2rem;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
}

.title {
  font-size: 80px;
  text-transform: uppercase;
  transform: rotate(10deg) translateX(calc(var(--x) / 10, 0px));
}
