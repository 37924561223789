.project-page {
  color: black;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.indiv-project {
  height: auto;
  display: flex;
  flex-direction: column;
}

.project-info {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 10% 70%;
  grid-template-rows: 50% 50%;
  align-items: center;
}

.project-image{
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  justify-content: space-around;
}

.project-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 50%;
  padding: .5rem;
}

.Github {
  grid-column: 2 / span 1;
  grid-row: 1 / span 1;
}

.live-site {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
}
.about-project {
  grid-column: 3 / span 1;
  grid-row: 1 / span 2;
  padding: 30px 60px 0 60px;
  align-self: baseline;
  text-align: left;
}

.Github, .live-site {
  justify-content: space-around;
  display: flex;
}

.project-info button {
  padding: 1rem;
  background-color: #28007e;
  color: white;
}

.project-copy {
  align-items: center;
  color: #8D1C38;
  font-family: Arial, Helvetica, sans-serif;
  padding: 2rem;
  border: 3px solid #8D1C38;
  background-color: rgba(141, 28, 56, 0.236);
  margin: 2rem auto;
  width: 50%;
  border-radius: 2rem;
  font-size: 1rem;
}