.construction-bg {
  --color-1: #28007e;
  --color-2: #77618c;
  height: 100%;
  min-height: 100dvh;
  width: 100%;
  background-color: #8d1c38;
  background-image: radial-gradient(
    circle farthest-side at var(--x, 100px) var(--y, 100px),
    var(--color-1) 0%,
    transparent 100%
  );
  /* transition: var(--color-1) 100ms linear, var(--color-2) 100ms linear; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.construction-font {
    font-family: "rosella-deco", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    font-size: 8dvw;
    position: fixed;
}