.testbutton {
  position: relative;
  border: none;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  height: 500px;
  width: 100%;
}

.moonwix-bg {
  background-color: #f5e9df;
  height: 100%;
  justify-content: center;
  z-index: 1;
}

/* .moonwix-cake {
  width: 80%;
} */

.moonwix-cake3 {
  width: 70%;
}

.moonwix-cake2 {
  width: 50%;
  padding: none;
  z-index: 2;
}

.logocake {
  display: flex;
  width: 100%;
  z-index: 2;
}

.moonwix-logo {
  width: 50%;
  z-index: 2;
}
.darkmode {
  background-color: darkblue;
  padding-top: 1rem;
  border: 1px solid yellow;
  z-index: 2;
}

.discoballz {
  display: flex;
  z-index: 2;
}

.ballz {
  width: 20%;
  z-index: 2;
}

@media (min-width: 720px) {
  .lightmode {
    padding-top: 14rem;
    z-index: 2;
  }
}

@media (max-width: 720px) {
  .lightmode {
    z-index: 2;
    padding-top: 5rem;
  }
}

.bgsparkles {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.shop-now {
  font-family: "cubano", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 6rem;
  color: #c4afc9;
  padding-top: 10rem;
}

.prodimgs {
  width: 20%;
}

.product-imgs {
  display: flex;
  justify-content: space-around;
  padding: 3rem 1rem;
}
