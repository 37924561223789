.home-link {
  color: #674675;
  font-size: 7vw;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}

.come-in {
  color: #674675;
}

.come-in:hover{
  color: #8d1c38;
}