.App {
  text-align: center;
  height: 100%;
  min-height: 135vh;
  background-color: #F2C2C6;
}

* {
  margin: 0;
  /* border: 1px solid black; */
}

a {
  text-decoration: none;
}