@media screen and (min-width: 1068px) {
.hi {
  color: #674675;
  font-family: "azo-sans-uber", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
}
}

@media screen and (max-width: 1068px) {
  .hi {
    color: #674675;
    font-family: "azo-sans-uber", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
  }
  .about-body {
    padding: 2rem;
  }
}

.about-body {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  /* padding: 0.5rem 2rem; */
  padding: 2rem;
  width: fit-content;
}

.about-body, .view-resume {
  border: 3px solid #674675;
  background-color: rgba(103, 70, 117, 0.396);
  border-radius: 2rem;
}
.view-resume {
  width: fit-content;
  padding: .5rem .75rem;
  margin: 2rem auto 0rem;
  font-size: .75rem;
  cursor: pointer;
}

.view-resume :hover {
  color: #F2C2C6;
}

.about-logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 70%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: .75rem;
}



.about-logo {
  height: 3rem;
  padding: .5rem;
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  margin: 1rem 1rem;
}

.skills :hover {
  transform: rotate(1080deg);
  transition: transform 1s ease-out;
}

.about-component {
  margin: 2rem 2rem;
}

.aboutbody-text {
  margin-top: 2dvh;
  line-height: 1.5rem;
}