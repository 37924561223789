@media screen and (min-width: 1001px) {
  .about-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:flex-start;
  }
  .about-img {
    width: 50dvw;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 1000px) {
  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-img {
    width: 50dvw;
    padding-bottom: 2rem;
  }
}

