.wink-project-title {
  font-size: 12dvw;
  font-family: "puffin-arcade-liquid", sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #ffffff;
  background-color: #65489d;
}

.wink-project-title:hover {
  color: black;
}

.project-titles {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.dev-design-title {
  font-family: "amandine", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 7dvw;
  color: #fec144;
  background-color: #1e1e1e;
}

.dev-design-title:hover,
.sydney-site-title:hover {
  color: white;
}

@font-face {
  font-family: VanillaRavioli;
  src: url(../../fonts/VanillaRavioli_Demo.ttf);
}

.sydney-site-title {
  font-family: VanillaRavioli;
  font-size: 7.5dvw;
  background-color: #764d40;
  color: #dcc1af;
}

