.Tabs {
  height: 100%;
  padding-bottom: 2rem;
}

ul.nav {
  width: 60%;
  color: white;
  margin: 2rem auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #8d1c38;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  font-family: "azo-sans-uber", sans-serif;
  font-weight: 400;
  font-size: 2dvw;
  padding-left: 0px;
  @media (max-width: 768px) {
    font-size: 4dvw;
  }
}

ul.nav li {
  width: 60%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}

ul.nav li:first-child {
  border-top-left-radius: 2rem;
}

ul.nav li:last-child {
  border-top-right-radius: 2rem;
}

ul.nav li:hover {
  background: rgba(165, 14, 112, 0.301);
}

ul.nav li.active {
  background: #8d1c38;
}

.TabContent {
  font-size: 2rem;
  text-align: center;
}

.outlet {
  background-color: #8d1c38;
  border: 1px solid #8d1c38;
  width: 60%;
  margin: auto;
  padding-bottom: 2rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.project-logo {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
  background-color: white;
  border-radius: 50%;
  padding: 0.5rem;
}

.FirstTab,
.SecondTab,
.ThirdTab {
  display: flex;
  gap: 2rem;
  align-items: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 768px) {
  .FirstTab,
  .SecondTab,
  .ThirdTab {
    padding: 2rem 2rem 0 2rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .FirstTab,
  .SecondTab,
  .ThirdTab {
    padding: 1rem 1rem 0 1rem;
    font-size: .75rem;
  }

    ul.nav {
    width: 90%;
  }

  .outlet {
    width: 90%;
  }
}

.project-img {
  width: 90%;
}

.links {
  border: 1px solid white;
  border-radius: 2rem;
  padding: 1rem 0;
  background-color: #f2c2c6;
}

.link-text {
  color: #8d1c38;
}

.links :hover, .projects-link-hover :hover, .project-copy :hover {
  color: #674675;
}

.projects-link {
  color: #f2c2c6;
}

.line {
  border-bottom: 2px dotted #f2c2c6;
  margin: 2rem auto;
  width: 30%;
}

.logos {
  height: 2rem;
}

.all-logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #f2c2c6;
  border: 1px solid black;
  width: fit-content;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding: .75rem 1rem;
  border-radius: 2rem;
}

.built-with {
  font-family: Arial, Helvetica, sans-serif;
  font-size: .75rem;
}

.url {
  color: white;
}
